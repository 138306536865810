import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PaymentOrder } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getAllRequests = (paymentOrders: PaymentOrder[]) =>
  paymentOrders.filter(
    (paymentOrder) => paymentOrder.charge_type === 'request',
  );

export const getAllPreAuthorizationItems = (paymentOrders: PaymentOrder[]) =>
  paymentOrders.filter(
    (paymentOrder) => paymentOrder.charge_type === 'pre_authorization',
  );

export const selectState = createFeatureSelector<State>('paymentOrders');

export const selectAllItems = featureAdapter.getSelectors(selectState)
  .selectAll;

export const selectById = (id: string) =>
  createSelector(
    selectAllItems,
    (paymentOrders: PaymentOrder[]) =>
      paymentOrders && paymentOrders.find((p) => p.id === +id),
  );

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectAllRequests = createSelector(selectAllItems, getAllRequests);

export const selectAllPreAuthorizationItems = createSelector(
  selectAllItems,
  getAllPreAuthorizationItems,
);
