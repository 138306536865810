import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { paymentOrders }) =>
    fromState.featureAdapter.setAll(paymentOrders, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.capturePreAuthorizationRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.capturePreAuthorizationSuccess, (state, { paymentOrder }) =>
    fromState.featureAdapter.updateOne(
      { id: paymentOrder.id, changes: paymentOrder },
      {
        ...state,
        isLoading: false,
        error: null,
      },
    ),
  ),
  on(fromActions.capturePreAuthorizationFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.releasePreAuthorizationRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.releasePreAuthorizationSuccess, (state, { paymentOrder }) =>
    fromState.featureAdapter.updateOne(
      { id: paymentOrder.id, changes: paymentOrder },
      {
        ...state,
        isLoading: false,
        error: null,
      },
    ),
  ),
  on(fromActions.releasePreAuthorizationFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.deleteRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.deleteSuccess, (state, { paymentOrderId }) =>
    fromState.featureAdapter.removeOne(paymentOrderId, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.deleteFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetState, () => fromState.initialState),
);

export function paymentOrdersReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
