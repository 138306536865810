import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { PaymentOrdersService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class PaymentOrdersStoreEffects {
  constructor(
    private dataService: PaymentOrdersService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ request }) =>
        this.dataService.load(request).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadSuccess({ paymentOrders: response.data }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  capturePreAuthorization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.capturePreAuthorizationRequest),
      switchMap(({ paymentOrderId, propertyId }) =>
        this.dataService
          .capturePreAuthorization(paymentOrderId, propertyId)
          .pipe(
            map((response: IResponseSuccess) => {
              this.notifications.updateSuccess();

              return fromActions.capturePreAuthorizationSuccess({
                paymentOrder: response.data[0],
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.capturePreAuthorizationFailure(error));
            }),
          ),
      ),
    ),
  );

  releasePreAuthorization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.releasePreAuthorizationRequest),
      switchMap(({ paymentOrderId, propertyId }) =>
        this.dataService
          .releasePreAuthorization(paymentOrderId, propertyId)
          .pipe(
            map((response: IResponseSuccess) => {
              this.notifications.updateSuccess();

              return fromActions.releasePreAuthorizationSuccess({
                paymentOrder: response.data[0],
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.releasePreAuthorizationFailure(error));
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ paymentOrderId, propertyId }) =>
        this.dataService.delete(paymentOrderId, propertyId).pipe(
          map(() => {
            this.notifications.deleteSuccess();
            return fromActions.deleteSuccess({
              paymentOrderId,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );

  resendEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resendEmailRequest),
      switchMap(({ paymentOrderId, propertyId, email }) =>
        this.dataService.resendEmail(paymentOrderId, propertyId, email).pipe(
          map(() => {
            this.notifications.updateSuccess();
            return fromActions.resendEmailSuccess({
              paymentOrderId,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.resendEmailFailure(error));
          }),
        ),
      ),
    ),
  );

  getWhatsappText$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getWhatsappTextRequest),
      switchMap(({ payment }) =>
        this.dataService.getWhatsappText(payment).pipe(
          map((request: any) => {
            return fromActions.getWhatsappTextSuccess({
              payment,
              message: request?.data?.text,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.getWhatsappTextFailure(error));
          }),
        ),
      ),
    ),
  );
}
