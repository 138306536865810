import { createAction, props } from '@ngrx/store';

import { LoadPaymentOrdersRequest, PaymentOrder } from '../../models';

export const loadRequest = createAction(
  '[Payment Orders] Load Request',
  props<{
    request: LoadPaymentOrdersRequest;
  }>(),
);

export const loadSuccess = createAction(
  '[Payment Orders] Load Success',
  props<{ paymentOrders: PaymentOrder[] }>(),
);

export const loadFailure = createAction(
  '[Payment Orders] Load Failure',
  props<{ error: any }>(),
);

export const capturePreAuthorizationRequest = createAction(
  '[Payment Orders] Capture Pre Authorization Request',
  props<{
    paymentOrderId: number;
    propertyId: number;
  }>(),
);

export const capturePreAuthorizationSuccess = createAction(
  '[Payment Orders] Capture Pre Authorization Success',
  props<{ paymentOrder: PaymentOrder }>(),
);

export const capturePreAuthorizationFailure = createAction(
  '[Payment Orders] Capture Pre Authorization Failure',
  props<{ error: any }>(),
);

export const releasePreAuthorizationRequest = createAction(
  '[Payment Orders] Release Pre Authorization Request',
  props<{
    paymentOrderId: number;
    propertyId: number;
  }>(),
);

export const releasePreAuthorizationSuccess = createAction(
  '[Payment Orders] Release Pre Authorization Success',
  props<{ paymentOrder: PaymentOrder }>(),
);

export const releasePreAuthorizationFailure = createAction(
  '[Payment Orders] Release Pre Authorization Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Payment Orders] Delete Request',
  props<{
    paymentOrderId: number;
    propertyId: number;
  }>(),
);

export const deleteSuccess = createAction(
  '[Payment Orders] Delete Success',
  props<{ paymentOrderId: number }>(),
);

export const deleteFailure = createAction(
  '[Payment Orders] Delete Failure',
  props<{ error: any }>(),
);

export const resendEmailRequest = createAction(
  '[Payment Orders] Resend Email Request',
  props<{
    paymentOrderId: number;
    email?: string;
    propertyId: number;
  }>(),
);

export const resendEmailSuccess = createAction(
  '[Payment Orders] Resend Email Success',
  props<{ paymentOrderId: number }>(),
);

export const resendEmailFailure = createAction(
  '[Payment Orders] Resend Email Failure',
  props<{ error: any }>(),
);

export const getWhatsappTextRequest = createAction(
  '[Payment Orders] Get Whatsapp Text Request',
  props<{
    payment: PaymentOrder;
  }>(),
);

export const getWhatsappTextSuccess = createAction(
  '[Payment Orders] Get Whatsapp Text Success',
  props<{ payment: PaymentOrder; message: string }>(),
);

export const getWhatsappTextFailure = createAction(
  '[Payment Orders] Get Whatsapp Text Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Payment Orders] Reset State');
